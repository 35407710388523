import { createContext, useState, PropsWithChildren } from 'react';
import axios from '../api/axios';
import { Operator, operatorDefault } from '../interfaces/Operator';
import defaultPicture from '../media/blank.png';

interface OperatorDataProps {
  operators: Operator[];
  setOperators: Function;
  selectedOperator: Operator;
  setSelectedOperator: Function;
  fetchOperator: Function;
}

interface Props {}

export const OperatorData = createContext<OperatorDataProps>({
  operators: [],
  setOperators: () => null,
  selectedOperator: operatorDefault,
  setSelectedOperator: () => null,
  fetchOperator: () => null,
});

export const OperatorDataProvider = ({
  children,
}: PropsWithChildren<Props>) => {
  const [operators, setOperators] = useState<Operator[]>([]);
  const [selectedOperator, setSelectedOperator] =
    useState<Operator>(operatorDefault);

  const fetchOperator = () => {
    // Get Kiosk's operators IDs
    const LoggedKiosk = localStorage.getItem('user');
    if (!LoggedKiosk) {
      console.error('Error geting Operator IDs');
      return;
    }

    const userObj = JSON.parse(LoggedKiosk);
    const operatorsIDs = userObj.id_users
      ? userObj.id_users.split(',').map((id: number) => Number(id))
      : [];

    // Get Operator's list from the server and filter by the array of ids
    axios
      .get<Operator[]>('/api/user/list')
      .then((res) => {
        const fetchedOperator = res.data
          .filter((user) => operatorsIDs.includes(user.id))
          .map((user) => ({ ...user, pic: user.pic ?? defaultPicture }));
        setOperators(fetchedOperator);
      })
      .catch((err) => console.log(err));
  };

  return (
    <OperatorData.Provider
      value={{
        operators,
        setOperators,
        selectedOperator,
        setSelectedOperator,
        fetchOperator,
      }}
    >
      {children}
    </OperatorData.Provider>
  );
};
