import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '../contexts/auth/AuthProvider';

// Pages
import Header from '../pages/Header';
import Login from '../pages/Login';
import Operators from '../pages/Operator';
import OperatorRoutes from './OperatorRoutes';

const KioskRoutes: React.FC = () => {
  const { isKioskLoggedIn, isOperatorLoggedIn } = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />}>
          {isKioskLoggedIn ? (
            <>
              <Route path="operator" element={<Operators />} />
              <Route index element={<Navigate to="/operator" />} />
            </>
          ) : (
            <>
              <Route index path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Route>
        {isOperatorLoggedIn ? (
          <Route path="/*" element={<OperatorRoutes />} />
        ) : (
          <Route path="*" element={<Navigate to="/operator" />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default KioskRoutes;
