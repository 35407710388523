import { useContext, useState, useLayoutEffect } from 'react';
import { Button, Card, Table, Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import TableInterface from './components/Interface';
import { Task } from '../../interfaces/Task';
import ValidateSelector from './components/ValidateSelector';
import { HeaderElementsContext } from '../../contexts/HeaderElements';
import { formatLocaleDate } from '../../utils/dates';
import { TaskContext } from '../../contexts/Tasks';
import { TemplateInterface } from '../../interfaces/Templates';
import { OperatorData } from '../../contexts/OperatorData';

function Validate() {
  const headerContext = useContext(HeaderElementsContext);
  const { tasks } = useContext(TaskContext);
  const { selectedOperator } = useContext(OperatorData);

  const navigate = useNavigate();
  const { state: templates } = useLocation();

  const [tableData, setTableData] = useState<TableInterface[]>([]);

  const setValidated = (index: number, validated: number) => {
    setTableData(
      tableData.map((task, idx) => {
        if (idx === index) return { ...task, validated };
        return task;
      })
    );
  };

  useLayoutEffect(() => {
    // Set header elements
    headerContext.setDispProfilePic(true);
    headerContext.setDispChangeUser(false);
    headerContext.setDispLogout(false);

    const templateIds = templates.map(
      (template: TemplateInterface) => template.id
    );

    const terminatedTasks: TableInterface[] = tasks
      .filter((task: Task) => {
        console.log(
          task.id,
          task.template?.next_action,
          (selectedOperator.role === 'admin' ||
            selectedOperator.permissions?.maintenance.includes('edit') ||
            templateIds.includes(task.template?.id)) &&
            task.template?.next_action === 'validate'
        );
        return (
          (selectedOperator.role === 'admin' ||
            selectedOperator.permissions?.maintenance.includes('edit') ||
            templateIds.includes(task.template?.id)) &&
          task.template?.next_action === 'validate'
        );
      })
      .map((task: Task) => {
        const template_name =
          task.template?.name === 'corrective'
            ? 'Corretiva'
            : task.template?.name === 'preventive'
            ? 'Preventiva'
            : task.template?.name;

        const date = task.scheduled_for ?? task.created_at;

        return {
          id: task.id,
          template_name,
          task_name: task.name,
          machine_name: task.machine_name,
          date,
          validated: 0,
        } as TableInterface;
      });

    setTableData(terminatedTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, templates]);

  return (
    <Container>
      <Card className="my-5 shadow">
        <Card.Body>
          {tableData.length > 0 ? (
            <Table size="lg" hover>
              <thead>
                <tr>
                  <th>Máquina</th>
                  <th>Intervenção</th>
                  <th>Motivo</th>
                  <th>Data de Pedido</th>
                  <th>Validação</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.machine_name}</td>
                    <td>{data.template_name}</td>
                    <td>{data.task_name}</td>
                    <td>{formatLocaleDate(data.date)}</td>
                    <td style={{ width: '200px', height: '55px' }}>
                      <ValidateSelector
                        index={index}
                        tasks={tableData}
                        setValidated={setValidated}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Table>
              <tbody>
                <tr>
                  <td colSpan={5}>Sem tarefas para validar.</td>
                </tr>
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
      <Button className="w-100 fs-2 shadow" onClick={() => navigate('..')}>
        Voltar
      </Button>
    </Container>
  );
}

export default Validate;
