import { Button } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const buttonStyle = {
  minWidth: '400px',
  height: '80px',
  fontSize: '30px',
};

interface Props {
  label: string;
  onClick: () => void;
}

const RequestButton: React.FC<Props> = ({ label, onClick }) => (
  <Button className="shadow" style={buttonStyle} onClick={() => onClick()}>
    <FontAwesomeIcon icon={faPlus} className="float-start mt-2" />
    <span>{`Requisitar ${label === 'corrective' ? 'Manutenção' : label}`}</span>
  </Button>
);

export default RequestButton;
