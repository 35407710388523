import { ModalProps } from "./ModalInterface";
import { Button, Form, Modal } from "react-bootstrap";

const inputStyle = {
  width: "100%",
  height: "65px",
  fontSize: "30px",
};

const buttonStyle = {
  width: "100%",
  height: "60px",
  fontSize: "30px",
};

function LogoutModal({
  show,
  onHide,
  onConfirm,
  password,
  setPassword,
}: ModalProps) {
  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    onConfirm();
    setPassword("");
  };

  const onHideHandler = () => {
    setPassword("");
    onHide(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => onHideHandler()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Verificar Autorização
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={(e) => submitHandler(e)}>
        <Modal.Body>
          <Form.Control
            type="password"
            placeholder="Password"
            inputMode="none"
            style={inputStyle}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="success" style={buttonStyle} type="submit">
            Confirmar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default LogoutModal;
