import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import { getAuth, ACCESS_TOKEN_KEY, STORAGE_KEY } from './AuthHelpers';

interface Props {}
interface AuthContextProps {
  isKioskLoggedIn: boolean;
  isOperatorLoggedIn: boolean;
  login: Function;
  logout: Function;
  getSession: Function;
  operatorAuth: Function;
  operatorQuit: Function;
  confirmSession: Function;
}

const AuthContext = createContext<AuthContextProps>({
  isKioskLoggedIn: false,
  isOperatorLoggedIn: false,
  login: () => null,
  logout: () => null,
  getSession: () => null,
  operatorAuth: () => null,
  operatorQuit: () => null,
  confirmSession: () => null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const MINUTES = 3;
const TIMEOUT = 1000 * 60 * MINUTES;

export const AuthProvider = ({ children }: PropsWithChildren<Props>) => {
  const [isKioskLoggedIn, setIsKioskLoggedIn] = useState(getAuth());
  const [isOperatorLoggedIn, setIsOperatorLoggedIn] = useState(false);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const resetTimeout = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      window.location.reload();
    }, TIMEOUT);
  };

  useEffect(() => {
    // Se kiosk não está definido, operador nunca está definido
    if (!isKioskLoggedIn) {
      setIsOperatorLoggedIn(false);
      localStorage.clear();
    }
  }, [isKioskLoggedIn]);

  useEffect(() => {
    if (isOperatorLoggedIn) {
      // Se operador está definido, kiosk está sempre definido
      setIsKioskLoggedIn(true);

      resetTimeout();
      window.addEventListener('mousemove', resetTimeout);
      window.addEventListener('keydown', resetTimeout);

      return () => {
        window.removeEventListener('mousemove', resetTimeout);
        window.removeEventListener('keydown', resetTimeout);
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
      };
    }
  }, [isOperatorLoggedIn]);

  const login = (username: string, password: string) => {
    toast.promise(
      axios
        .post('/kiosk/login', { username, password })
        .then((res) => {
          localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(res.data));

          if ('api_token' in res.data) setIsKioskLoggedIn(true);
        })
        .catch((err) => {
          if (!err?.response) {
            console.error('No server response');
            return Promise.reject('Sem resposta do servidor');
          }
          if (err.response?.status === 400) {
            console.error('Missing UserName or Password');
            return Promise.reject('Falta utilizador ou password');
          }
          if (err.response?.status === 401) {
            console.error('Unauthorized');
            return Promise.reject('Nome de utilizador ou password inválidos');
          }
          console.error('Login Failed');
          return Promise.reject('Inicio de sessão falhou');
        }),
      {
        pending: 'A iniciar sessão...',
        success: 'Inicio de sessão bem sucedido!',
        error: {
          render({ data }) {
            return `${data}`;
          },
        },
      }
    );
  };

  const logout = (action: Function) => {
    axios
      .get('/logout')
      .then((res) => {
        setIsKioskLoggedIn(false);
        action();
      })
      .catch(() => {
        console.error('Failed to logout');
      });
  };

  const getSession = (action: Function) => {
    axios
      .get('/api/kiosk/session')
      .then((res) => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(res.data));
        action();
      })
      .catch((err) => {
        console.error(err);
        setIsKioskLoggedIn(false);
      });
  };

  const operatorAuth = (
    id: string,
    pin: string,
    onSuccess: (path: string) => void
  ) => {
    toast.promise(
      axios
        .post('/operator/auth', { id, pin })
        .then(() => {
          console.log('Operator authenticated successfuly');
          setIsOperatorLoggedIn(true);

          onSuccess(`/panel/${id}`);
        })
        .catch((err) => {
          console.error('Operator authentication failed');
          if (!err?.response) {
            console.error('No server response');
            return Promise.reject('Sem resposta do servidor');
          }

          if (err.response?.status === 500)
            return Promise.reject('Erro no servidor!');

          return Promise.reject('Pin inválido!');
        }),
      {
        pending: '',
        success: 'Operador autenticado com sucesso!',
        error: {
          render({ data }) {
            return `${data}`;
          },
        },
      }
    );
  };

  const operatorQuit = () => {
    axios
      .get('/operator/change')
      .then(() => {
        console.log('Operator change success');
        setIsOperatorLoggedIn(false);
      })
      .catch(() => console.log('Operator change failed!'));
  };

  const confirmSession = (password: string, action: Function) => {
    toast.promise(
      axios
        .post('/kiosk/confirm', { password })
        .then((res) => {
          console.log(res.data);
          action();
        })
        .catch((err) => {
          if (!err?.response) {
            console.error('No server response');
            return Promise.reject('Sem resposta do servidor');
          }
          if (err.response?.status === 401) {
            console.error('Unauthorized');
            return Promise.reject('Password inválida!');
          }
          console.error(err);
        }),
      {
        pending: '',
        success: 'Sessão terminada com sucesso!',
        error: {
          render({ data }) {
            return `${data}`;
          },
        },
      }
    );
  };

  return (
    <AuthContext.Provider
      value={{
        isKioskLoggedIn,
        isOperatorLoggedIn,
        login: login,
        logout: logout,
        getSession: getSession,
        operatorAuth: operatorAuth,
        operatorQuit: operatorQuit,
        confirmSession: confirmSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
