import { useState, createContext, PropsWithChildren } from "react";
import axios from "../api/axios";
import { Task } from "../interfaces/Task";

interface TaskContextProps {
  fetchTasksById: Function;
  tasks: Task[];
}

export const TaskContext = createContext<TaskContextProps>(
  {} as TaskContextProps,
);

interface Props {}

export const TaskContextProvider = ({ children }: PropsWithChildren<Props>) => {
  const [tasks, setTasks] = useState<Task[]>([]);

  const fetchTasksById = () => {
    axios
      .get("/api/task/list/by-user")
      .then((res) => {
        setTasks(res.data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <TaskContext.Provider value={{ fetchTasksById, tasks }}>
      {children}
    </TaskContext.Provider>
  );
};
