export function formatLocaleDate(date: Date | number): string {
  const inputDate = new Date(date);

  if (isNaN(inputDate.getTime())) return "";

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const timeDiff = Math.floor(
    (inputDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24),
  );

  if (Math.abs(timeDiff) < 2) {
    const timeString = inputDate.toLocaleTimeString("pt", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const dateKey =
      timeDiff === -1 ? "Ontem " : timeDiff === 0 ? "Hoje " : "Amanhã ";

    return dateKey + timeString;
  }

  const isSameYear = inputDate.getFullYear() === currentDate.getFullYear();
  return inputDate.toLocaleDateString("pt", {
    year: isSameYear ? undefined : "numeric",
    month: isSameYear ? "long" : "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}
