import { Button } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const buttonStyle = {
  minWidth: '400px',
  height: '80px',
  fontSize: '30px',
};

interface Props {
  onClick: () => void;
}

const ValidationButton: React.FC<Props> = ({ onClick }) => (
  <Button
    className="shadow"
    variant="success"
    style={buttonStyle}
    onClick={() => onClick()}
  >
    <FontAwesomeIcon icon={faCheck} className="float-start mt-2" />
    <span>Validar Manutenção</span>
  </Button>
);

export default ValidationButton;
