
export interface Machine {
  id: number;
  name: string;
  possibleFaults: string[];
}

export const machineDefault = {
  id: 0,
  name: '',
  possibleFaults: []
}
