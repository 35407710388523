import { Button, Container } from 'react-bootstrap/esm';
import { faDeleteLeft, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumPadProps } from './NumpadInterface';
import { isMobile } from 'react-device-detect';

function NumPad({ pin, setPin, digitLimit }: NumPadProps) {
  const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

  const buttonStyle = {
    width: '32%',
    height: 'calc(25% - 1px)',
    paddingTop: '1px',
    paddingBottom: '1px',
    fontSize: '5vh',
  };
  const inputStyle = {
    fontSize: '5vh',
  };

  const addPinDigit = (digit: string) => {
    let newPin = pin;
    switch (digit) {
      case 'x':
        newPin = '';
        break;
      case 'r':
        newPin = pin.slice(0, -1);
        break;
      default:
        if (pin.length < digitLimit) newPin += digit;
        break;
    }
    setPin(newPin);
  };

  const onChangeNumberOnly = (value: string) => {
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setPin(value);
    }
  };

  return (
    <Container className="h-100">
      <Container className="h-25 input-group input-group-lg">
        <input
          id="inputCode"
          type="password"
          className="form-control text-center mb-3"
          placeholder="Insira pin"
          style={inputStyle}
          value={pin}
          onChange={(e) => onChangeNumberOnly(e.target.value)}
          readOnly={isMobile}
        />
      </Container>
      <Container className="h-75 d-flex flex-wrap gap-2 justify-content-between">
        {keys.map((key) => (
          <Button
            key={key}
            variant="outline-dark"
            onClick={() => addPinDigit(key)}
            style={buttonStyle}
          >
            {key}
          </Button>
        ))}
        <Button
          key={'x'}
          variant="link"
          onClick={() => addPinDigit('x')}
          style={buttonStyle}
        >
          <FontAwesomeIcon icon={faX} style={{ color: 'black' }} />
        </Button>
        <Button
          key={0}
          variant="outline-dark"
          onClick={() => addPinDigit('0')}
          style={buttonStyle}
        >
          0
        </Button>
        <Button
          key={'r'}
          variant="link"
          onClick={() => addPinDigit('r')}
          style={buttonStyle}
        >
          <FontAwesomeIcon icon={faDeleteLeft} style={{ color: 'black' }} />
        </Button>
      </Container>
    </Container>
  );
}

export default NumPad;
