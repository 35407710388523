import { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { ModalProps } from './NumpadInterface';
import Numpad from './Numpad';

const buttonStyle = {
  width: '48%',
  height: '100%',
  fontSize: '7mm',
};

const NumpadModal: React.FC<ModalProps> = function ({
  show,
  onHide,
  confirmHandler,
  digitLimit,
}: ModalProps) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue('');
    return () => setValue('');
  }, [show]);

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    confirmHandler(value);
  };

  return (
    <Modal
      style={{ width: '100vw' }}
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Entre na sua conta
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={submitHandler}>
        <Modal.Body>
          <Numpad pin={value} setPin={setValue} digitLimit={digitLimit} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button style={buttonStyle} variant="secondary" onClick={onHide}>
            Cancelar
          </Button>
          <Button style={buttonStyle} variant="success" type="submit">
            Confirmar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NumpadModal;
