export interface MaintenanceRequest {
  name: string;
  description: string;
  scheduled_for: Date | null;
  id_template?: number;
  id_machine?: number;
}

export const maintenanceRequestDefault: MaintenanceRequest = {
  name: '',
  description: '',
  scheduled_for: null,
  id_template: undefined,
  id_machine: undefined,
};
