import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface Props {
  message: string;
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
}

function ConfirmModal({ message, show, onHide, onConfirm }: Props) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Ação</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-100 d-flex gap-2">
          <Button className="w-50" variant="secondary" onClick={() => onHide()}>
            Cancelar
          </Button>
          <Button
            className="w-50"
            variant="primary"
            onClick={() => onConfirm()}
          >
            Confirmar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
