import axios from '../../api/axios';
import { useContext, useLayoutEffect, useState } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HeaderElementsContext } from '../../contexts/HeaderElements';
import { OperatorData } from '../../contexts/OperatorData';
import RequestButton from './components/RequestButton';
import ValidationButton from './components/ValidationButton';
import HistoryTable from './components/HistoryTable';
import { TemplateInterface } from '../../interfaces/Templates';

const Panel: React.FC = () => {
  const navigate = useNavigate();
  const headerContext = useContext(HeaderElementsContext);
  const { selectedOperator } = useContext(OperatorData);
  const [templates, setTemplates] = useState<TemplateInterface[]>([]);

  useLayoutEffect(() => {
    // Set header elements
    headerContext.setDispProfilePic(true);
    headerContext.setDispChangeUser(true);
    headerContext.setDispLogout(false);

    axios.get('/api/template/list/by-user').then((res) => {
      setTemplates(res.data);
    });
  }, []);

  return (
    <Container className="d-flex flex-column">
      <Stack gap={4} className="col-md-5 mx-auto py-5">
        <h1 className="text-center">Painel de Manutenção</h1>
        {templates
          .filter(
            (template) =>
              (selectedOperator.role === 'admin' ||
                selectedOperator.permissions?.maintenance.includes('edit') ||
                template.actions.filter((a) => a.name === 'create').length) &&
              !template.schedule
          )
          .map((template) => (
            <RequestButton
              key={template.id}
              label={template.name}
              onClick={() => navigate('callmaintenance', { state: template })}
            />
          ))}
        <ValidationButton
          onClick={() =>
            navigate('validate', {
              state: templates.filter(
                (template) =>
                  !!template.actions.filter((a) => a.name === 'validate').length
              ),
            })
          }
        />
      </Stack>
      <HistoryTable />
    </Container>
  );
};

export default Panel;
