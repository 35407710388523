import { Alert } from 'react-bootstrap';
import { useState, useRef } from 'react';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import TableInterface from './Interface';
import ConfirmModal from './ConfirmModal';
import axios from '../../../api/axios';

interface Props {
  index: number;
  tasks: TableInterface[];
  setValidated: Function;
}

function ValidateSelector({ index, tasks, setValidated }: Props) {
  const validateTask = () => {
    toast.promise(
      axios
        .post(`/api/task/${tasks[index].id}/validate`, { id: tasks[index].id })
        .then((res) => {
          setValidated(index, 1);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          return Promise.reject('Erro ao validar tarefa');
        }),
      {
        pending: 'A validar...',
        success: 'Tarefa validada.',
        error: {
          render({ data }) {
            return `${data}`;
          },
        },
      }
    );
  };

  const invalidateTask = () => {
    toast.promise(
      axios
        .post(`/api/task/${tasks[index].id}/invalidate`, {
          task_id: tasks[index].id,
          task_name: tasks[index].task_name,
        })
        .then((res) => {
          setValidated(index, 2);
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject('Erro ao invalidar tarefa');
        }),
      {
        pending: 'A invalidar...',
        success: 'Tarefa invalidada.',
        error: {
          render({ data }) {
            return `${data}`;
          },
        },
      }
    );
  };

  const [modalMsg, setModalMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const confirmFunction = useRef(() => {});

  switch (tasks[index].validated) {
    case 0:
      return (
        <div className="d-flex gap-3">
          <FontAwesomeIcon
            onClick={() => {
              confirmFunction.current = validateTask;
              setModalMsg('Tem a certeza que pretende validar a tarefa?');
              setShowModal(true);
            }}
            type="button"
            size="2xl"
            icon={faCheck}
            style={{ color: '#49a835' }}
          />
          <FontAwesomeIcon
            onClick={() => {
              confirmFunction.current = invalidateTask;
              setModalMsg('Tem a certeza que pretende invalidar a tarefa?');
              setShowModal(true);
            }}
            type="button"
            size="2xl"
            icon={faXmark}
            style={{ color: '#a51d2d' }}
          />
          <ConfirmModal
            message={modalMsg}
            show={showModal}
            onConfirm={() => confirmFunction.current()}
            onHide={() => setShowModal(false)}
          />
        </div>
      );
    case 1:
      return (
        <Alert variant="success" className="text-center p-1 m-0 ">
          <FontAwesomeIcon
            className="me-2"
            size="xl"
            icon={faCheck}
            style={{ color: '#49a835' }}
          />
          Válida
        </Alert>
      );
    case 2:
      return (
        <Alert variant="danger" className="text-center p-1 m-0">
          <FontAwesomeIcon
            className="me-2"
            size="xl"
            icon={faXmark}
            style={{ color: '#a51d2d' }}
          />
          Inválida
        </Alert>
      );
    default:
      return <div></div>;
  }
}

export default ValidateSelector;
