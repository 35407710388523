type Role = 'admin' | 'user' | 'user_web' | 'user_kiosk';
const regularActions = ['view', 'edit', 'delete'] as const;
const settingsActions = ['edit', 'delete'] as const;

interface Permissions {
  maintenance: (typeof regularActions)[number][];
  machines: (typeof regularActions)[number][];
  teams: (typeof regularActions)[number][];
  users: (typeof regularActions)[number][];
  templates: (typeof regularActions)[number][];
  settings: (typeof settingsActions)[number][];
}

export interface Operator {
  id: number;
  name: string;
  email: string;
  phone: string;
  pic: string;
  password: string;
  pin: string;
  role: Role;
  permissions?: Permissions;
  created_at: string;
  updated_at: string;
  updated_by: number;
  deleted_at: string;
  id_company: number;
}

export const operatorDefault: Operator = {
  id: 0,
  name: '',
  email: '',
  phone: '',
  pic: '',
  password: '',
  pin: '',
  role: 'user_kiosk',
  created_at: '',
  updated_at: '',
  updated_by: -1,
  deleted_at: '',
  id_company: -1,
};
