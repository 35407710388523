import axios from '../../api/axios';
import { useContext, useEffect, useState } from 'react';
import {
  Card,
  Container,
  Row,
  Col,
  Form,
  FloatingLabel,
  Button,
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { HeaderElementsContext } from '../../contexts/HeaderElements';
import { Machine, machineDefault } from '../../interfaces/Machine';
import {
  MaintenanceRequest,
  maintenanceRequestDefault,
} from '../../interfaces/Maintenance';
import { toast } from 'react-toastify';

function CallMaintenance() {
  const headerContext = useContext(HeaderElementsContext);
  const navigate = useNavigate();
  const { state: template } = useLocation();
  const [machines, setMachines] = useState<Machine[]>([]);

  const [otherFault, setOtherFault] = useState(false);
  const [maintenanceRequest, setMaintenanceRequest] =
    useState<MaintenanceRequest>(maintenanceRequestDefault);

  useEffect(() => {
    // Set header elements
    headerContext.setDispProfilePic(true);
    headerContext.setDispChangeUser(false);
    headerContext.setDispLogout(false);

    setMaintenanceRequest((prev) => ({ ...prev, id_template: template.id }));
    // Pedido de Intervenção diferente
    if (template.name !== 'corretive')
      setMaintenanceRequest((prev) => ({ ...prev, name: template.name }));

    axios
      .get('/api/fault/by-machine')
      .then((res) => {
        const newMachineList = res.data.map((machine: any) => ({
          id: machine['id'],
          name: machine['name'],
          possibleFaults: machine['faults'],
        }));

        setMachines(newMachineList);
      })
      .catch((error) => {
        console.error('Error fetching machines:', error);
      });
  }, []);

  const selectMachine = (machineName: string) => {
    const newMachine =
      machines.find((machine) => machine.name === machineName) ??
      machineDefault;
    setMaintenanceRequest((prev) => ({ ...prev, id_machine: newMachine.id }));
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (maintenanceRequest)
      toast.promise(
        axios
          .put('/api/task', maintenanceRequest)
          .then(() => navigate('..'))
          .catch((err) => {
            if (!err?.response) {
              console.error('No server response');
              return Promise.reject('Sem resposta do servidor');
            }

            console.error('Error posting request:', err);
            return Promise.reject('Erro ao enviar requisição');
          }),
        {
          pending: 'A publicar requisição...',
          success: 'Requisição publicada com sucesso!',
          error: {
            render({ data }) {
              return `${data}`;
            },
          },
        }
      );
  };

  return (
    <Container>
      <Card className="my-5 shadow">
        <Card.Body>
          <Form onSubmit={submitHandler}>
            <Form.Group as={Row} className="fs-3 mb-3">
              <Form.Label column md="3" lg="2">
                Máquina:
              </Form.Label>
              <Col md="9" lg="10">
                <Form.Select
                  onChange={(e) => selectMachine(e.target.value)}
                  className="mt-1"
                  size="lg"
                  required
                >
                  <option key="machine_-1" hidden value="">
                    Selectione Máquina
                  </option>
                  {machines.map((machine, index) => (
                    <option key={'machine' + index}>{machine.name}</option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>

            {template?.name === 'corrective' && (
              <>
                <Form.Group as={Row} className="fs-3 mb-3">
                  <Form.Label column md="3" lg="2">
                    Avaria:
                  </Form.Label>

                  <Col md="9" lg="10">
                    <Form.Select
                      onChange={(e) => {
                        if (e.target.value === 'other') {
                          setOtherFault(true);
                          return;
                        }

                        setOtherFault(false);
                        setMaintenanceRequest((prev) => ({
                          ...prev,
                          name: e.target.value.replace(/\s{2,}/g, ' ').trim(),
                        }));
                      }}
                      disabled={!maintenanceRequest.id_machine}
                      defaultValue=""
                      className="mt-1"
                      size="lg"
                      required
                    >
                      <option key="fault_-2" hidden value="">
                        Selectione Avaria
                      </option>
                      <option key="fault_-1" value="other">
                        Outra
                      </option>
                      {machines
                        .find(
                          (machine) =>
                            machine.id === maintenanceRequest.id_machine
                        )
                        ?.possibleFaults.map((fault, index) => (
                          <option key={'fault_' + index} value={fault}>
                            {fault}
                          </option>
                        )) ?? ''}
                    </Form.Select>
                  </Col>
                </Form.Group>

                <FloatingLabel
                  controlId="floatingTextarea"
                  label="Nome da avaria"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Nome da avaria"
                    minLength={3}
                    maxLength={45}
                    disabled={!otherFault}
                    onChange={(e) =>
                      setMaintenanceRequest((prev) => ({
                        ...prev,
                        name: e.target.value.replace(/\s{2,}/g, ' ').trim(),
                      }))
                    }
                    required={maintenanceRequest.name === ''}
                  />
                </FloatingLabel>
              </>
            )}

            <FloatingLabel
              controlId="floatingTextarea"
              label="Notas"
              className="mb-3"
            >
              <Form.Control
                as="textarea"
                placeholder="Notas"
                style={{
                  resize: 'none',
                  width: '100%',
                  height: '6cm',
                  maxHeight: '6cm',
                }}
                maxLength={245}
                onChange={(e) => {
                  setMaintenanceRequest({
                    ...maintenanceRequest,
                    description: e.target.value,
                  });
                }}
              />
            </FloatingLabel>
            <Form.Group className="d-flex justify-content-between gap-2">
              <Button
                variant="secondary"
                className="w-50"
                size="lg"
                onClick={() => navigate('..')}
              >
                Cancelar
              </Button>
              <Button type="submit" className="w-50" size="lg">
                Submeter
              </Button>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default CallMaintenance;
