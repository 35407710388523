import { useEffect, useContext, useState } from 'react';
import { Table, Badge } from 'react-bootstrap';

import { formatLocaleDate } from '../../../utils/dates';
import { Task, Action } from '../../../interfaces/Task';
import { TaskContext } from '../../../contexts/Tasks';

interface TableInterface {
  type: Action;
  next_action: Action | null;
  machine: string;
  template: string;
  fault: string;
  created_at: Date;
}

const TABLE_MAX_ROW_NUMBER = 30;

const HistoryTable: React.FC = () => {
  const [tableData, setTableData] = useState<TableInterface[]>([]);
  const taskContext = useContext(TaskContext);

  const tasksToTableData = (tasks: Task[]) => {
    const data: TableInterface[] = tasks.map((task) => {
      const template_name = task.template
        ? task.template.name === 'corrective'
          ? 'Corretiva'
          : task.template.name === 'preventive'
          ? 'Preventiva'
          : task.template.name
        : '';

      return {
        type: task.activity[task.activity.length - 1]?.type ?? 'created',
        next_action: task.template?.next_action ?? null,
        machine: task.machine_name,
        template: template_name,
        fault: task.name,
        created_at: new Date(task.created_at),
      };
    });

    data.sort((a, b) => b.created_at.getTime() - a.created_at.getTime());
    return data;
  };

  useEffect(() => {
    taskContext.fetchTasksById();
  }, []);

  useEffect(() => {
    const data = tasksToTableData(taskContext.tasks);
    setTableData(data.splice(0, TABLE_MAX_ROW_NUMBER));
  }, [taskContext.tasks]);

  const validation = (data: TableInterface) => {
    if (!data.next_action || data.type === 'validate')
      return <Badge bg="success">Concluída</Badge>;

    if (data.type === 'invalidate') return <Badge bg="danger">Inválida</Badge>;

    if (data.type === 'created') return <Badge bg="primary">Submetida</Badge>;

    if (data.type === 'end') return <Badge bg="warning">Para Validação</Badge>;

    return <Badge bg="secondary">Pendente</Badge>;
  };

  return (
    <div className="overflow-auto" style={{ minHeight: '215px' }}>
      <Table className="table" responsive>
        <thead>
          <tr>
            <th colSpan={5}>
              <h3>Histórico de atividade</h3>
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th>Estado</th>
            <th>Máquina</th>
            <th>Intervenção</th>
            <th>Motivo</th>
            <th>Data de Pedido</th>
          </tr>
        </thead>
        <tbody className="table-group-divider ">
          {tableData.map((data, index) => (
            <tr key={index}>
              <td>{validation(data)}</td>
              <td>{data.machine}</td>
              <td>{data.template}</td>
              <td>{data.fault}</td>
              <td>{formatLocaleDate(data.created_at)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default HistoryTable;
