import { useContext, useEffect, useState } from 'react';
import { HeaderElementsContext } from '../../contexts/HeaderElements';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import NumpadModal from './components/NumpadModal';
import { useAuth } from '../../contexts/auth/AuthProvider';
import { OperatorData } from '../../contexts/OperatorData';
import { Operator } from '../../interfaces/Operator';

const Operators: React.FC = () => {
  const operatorData = useContext(OperatorData);
  const headerContext = useContext(HeaderElementsContext);
  const navigate = useNavigate();
  const auth = useAuth();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Set header elements
    headerContext.setDispProfilePic(false);
    headerContext.setDispChangeUser(false);
    headerContext.setDispLogout(true);

    if (auth.isKioskLoggedIn)
      auth.getSession(() => operatorData.fetchOperator());
  }, [auth]);

  const showAuthModal = (operator: Operator) => {
    setShowModal(true);
    operatorData.setSelectedOperator(operator);
  };

  const operatorAuthHandler = (pin: string) => {
    const id = operatorData.selectedOperator.id.toString();
    auth.operatorAuth(id, pin, navigate);
  };

  return (
    <>
      <Container className="pt-3">
        <Row>
          {operatorData.operators.map((operator, index) => (
            <Col key={index} md={3} sm={6} className="py-3" type="button">
              <Card className="shadow" onClick={() => showAuthModal(operator)}>
                <Card.Img
                  variant="top"
                  style={{
                    objectFit: 'cover',
                    aspectRatio: '1',
                  }}
                  src={operator.pic}
                />
                <Card.Body
                  className={'d-flex flex-wrap'}
                  style={{ height: '20%' }}
                >
                  <Card.Title className={'text-truncate w-100'}>
                    {operator.name}
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <NumpadModal
        show={showModal}
        onHide={() => setShowModal(false)}
        confirmHandler={operatorAuthHandler}
        digitLimit={4}
      />
    </>
  );
};

export default Operators;
