import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import { Button, Nav } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { useContext, useState } from 'react';
import { OperatorData } from '../../contexts/OperatorData';
import { HeaderElementsContext } from '../../contexts/HeaderElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPeopleArrows,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/auth/AuthProvider';
import LogoutModal from './components/LogoutModal';
import Logo from '../../media/default-dark.png';

const Header: React.FC = () => {
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [password, setPassword] = useState('');

  const operatorData = useContext(OperatorData);
  const headerElements = useContext(HeaderElementsContext);
  const auth = useAuth();

  const logOutHandler = () => {
    auth.confirmSession(password, () => {
      auth.logout(() => {
        setShowLogoutModal(false);
      });
    });
  };

  return (
    <>
      <Navbar
        bg="primary"
        className="justify-content-between text-white shadow"
        style={{ height: '65px' }}
      >
        <Container>
          <Navbar.Brand>
            {headerElements.dispProfilePic ? (
              <div className="d-flex justify-content-between gap-3">
                <Image
                  src={operatorData.selectedOperator.pic}
                  width="45"
                  height="45"
                  style={{ objectFit: 'cover' }}
                  roundedCircle
                  className="shadow"
                />
                <span className="align-self-center" style={{ color: 'white' }}>
                  {operatorData.selectedOperator.name}
                </span>
              </div>
            ) : (
              <Image src={Logo} style={{ width: '120px' }} />
            )}
          </Navbar.Brand>
          <Nav className="ms-auto">
            {headerElements.dispChangeUser && (
              <Button className="fs-5" onClick={() => auth.operatorQuit()}>
                <FontAwesomeIcon icon={faPeopleArrows} className="pe-2" />
                Mudar utilizador
              </Button>
            )}
            {headerElements.dispLogout && (
              <Button className="fs-5" onClick={() => setShowLogoutModal(true)}>
                <FontAwesomeIcon icon={faRightFromBracket} className="pe-2" />
                Terminar Sessão
              </Button>
            )}
          </Nav>
        </Container>
      </Navbar>

      <LogoutModal
        show={showLogoutModal}
        onHide={setShowLogoutModal}
        onConfirm={logOutHandler}
        password={password}
        setPassword={setPassword}
      />

      <div
        className="d-flex justify-content-center"
        style={{ height: 'calc(100vh - 65px)' }}
      >
        <Outlet />
      </div>
    </>
  );
};

export default Header;
