import { useContext, useEffect, useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import LoginPic from '../../media/user.png';
import { HeaderElementsContext } from '../../contexts/HeaderElements';
import { useAuth } from '../../contexts/auth/AuthProvider';

const Login: React.FC = () => {
  const auth = useAuth();
  const headerContext = useContext(HeaderElementsContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    // Set header elements
    headerContext.setDispProfilePic(false);
    headerContext.setDispChangeUser(false);
    headerContext.setDispLogout(false);
  }, []);

  const loginHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    auth.login(username, password);
  };

  return (
    <Container>
      <Card className="my-5 shadow">
        <Card.Img
          src={LoginPic}
          variant="top"
          alt="Login"
          className="mx-auto my-5"
          style={{ width: '14rem' }}
        />
        <Card.Body>
          <Form onSubmit={loginHandler} className="d-flex flex-column gap-3">
            <Form.Control
              id="username"
              type="text"
              placeholder="Utilizador"
              onChange={(e) => setUsername(e.target.value)}
              size="lg"
              required
            />

            <Form.Control
              id="password"
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              size="lg"
              required
            />
            <Button type="submit" variant="primary" size="lg">
              Entrar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Login;
