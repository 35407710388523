import { Route, Routes } from 'react-router-dom';

// Pages
import Header from '../pages/Header';
import Panel from '../pages/Panel';
import CallMaintenance from '../pages/CallMaintenance';
import Validate from '../pages/Validate';
import Error404 from '../pages/Error404';

const OperatorRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="panel/:id" element={<Header />}>
        <Route index element={<Panel />} />
        <Route path="callmaintenance" element={<CallMaintenance />} />
        <Route path="validate" element={<Validate />} />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default OperatorRoutes;
