import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import error404 from "../media/404-error.png";

function Error404() {
  const navigate = useNavigate();
  return (
    <div className="mt-5 d-flex flex-column align-items-center justify-content-center gap-3">
      <h1>Página não Encontrada</h1>
      <img src={error404} className="w-25" />
      <div className="d-flex flex-column gap-5 mw-75 mt-4">
        <Button
          className="w-100 fs-1"
          variant="primary"
          size="lg"
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
      </div>
    </div>
  );
}

export default Error404;
