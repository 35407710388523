import { createContext, useState, PropsWithChildren } from 'react';

interface HeaderElementsContextProps {
  setDispProfilePic: Function;
  dispProfilePic: boolean;
  setDispChangeUser: Function;
  dispChangeUser: boolean;
  setDispLogout: Function;
  dispLogout: boolean;
}

export const HeaderElementsContext = createContext<HeaderElementsContextProps>({
  setDispProfilePic: () => null,
  dispProfilePic: false,
  setDispChangeUser: () => null,
  dispChangeUser: false,
  setDispLogout: () => null,
  dispLogout: false,
});

export const HeaderElementsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [dispProfilePic, setDispProfilePic] = useState(false);
  const [dispChangeUser, setDispChangeUser] = useState(false);
  const [dispLogout, setDispLogout] = useState(false);

  return (
    <HeaderElementsContext.Provider
      value={{
        setDispProfilePic,
        dispProfilePic,
        setDispChangeUser,
        dispChangeUser,
        setDispLogout,
        dispLogout,
      }}
    >
      {children}
    </HeaderElementsContext.Provider>
  );
};
