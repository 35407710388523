const ACCESS_TOKEN_KEY = 'accessToken';
const STORAGE_KEY = 'user';

const getAuth = (): boolean => {
  if (!localStorage) return false;

  return !!localStorage.getItem('accessToken');
};

export { getAuth, ACCESS_TOKEN_KEY, STORAGE_KEY };
