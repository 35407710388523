import KioskRoutes from './routing/KioskRoutes';

// Toast
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'; // this has to be after ReactToastify.css to overload the css

// Contexts
import { OperatorDataProvider } from './contexts/OperatorData';
import { TaskContextProvider } from './contexts/Tasks';

function App() {
  return (
    <>
      <OperatorDataProvider>
        <TaskContextProvider>
          <KioskRoutes />
        </TaskContextProvider>
      </OperatorDataProvider>
      <ToastContainer
        position="bottom-right"
        transition={Flip}
        limit={3}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
